import { Controller } from "@hotwired/stimulus";
import PaystackPop from "@paystack/inline-js";

export default class extends Controller {
  connect() {
    if (!this.currencyTarget.value) {
      this.currencyTarget.value = "NGN";
    }

    const purposesData = JSON.parse(this.purposesTarget.value);
    this.purposes = JSON.parse(this.purposesTarget.value);

    this.updateAmount(this.purposeTarget.value, this.currencyTarget.value);
    this.disableStateSelect();
    this.setupStateInteraction();
    this.firstNameTarget.addEventListener("input", () =>
      this.validateFirstName()
    );
    this.lastNameTarget.addEventListener("input", () =>
      this.validateLastName()
    );
  }

  static get targets() {
    return [
      "form",
      "client_id",
      "subaccounts",
      "paystackPublic",
      "flutterwavePublic",
      "remitaPublic",
      "remitaBillerPublic",
      "purpose",
      "purposes",
      "currency",
      "amount",
      "amount_",
      "currency_",
      "amounts",
      "newAmount",
      "email",
      "reference",
      "paymentMethod",
      "country",
      "state",
      "firstName",
      "firstNameError",
      "lastName",
      "lastNameError",
      "phone",
      "categoryError",
      "transactionStatus",
      "selectWrapper",
    ];
  }

  get amountsValue() {
    return this.amountsTarget.value;
  }

  get purposesValue() {
    return this.purposesTarget.value;
  }

  disableStateSelect() {
    let stateSelect = this.stateTarget;
    if (stateSelect) {
      stateSelect.disabled = true;
    }
  }

  setupStateInteraction() {
    let selectWrapper = this.selectWrapperTarget;
    let stateError = document.getElementById("paymentsStateError");
    let countrySelect = this.countryTarget;

    selectWrapper.addEventListener("click", (event) => {
      if (
        countrySelect.value === "" ||
        countrySelect.value === "-1" ||
        countrySelect.value === "None"
      ) {
        stateError.style.display = "block";
      } else {
        stateError.style.display = "none";
      }
    });
  }

  completePayment(event) {
    event.preventDefault();

    this.amount_Target.value = this.amountTarget.value;
    this.currency_Target.value = this.currencyTarget.value;
    let isValid = this.validateForm();

    if (!isValid) {
      return;
    }

    let subaccounts = JSON.parse(this.subaccountsTarget.value).filter(
      (subaccount) =>
        subaccount.subaccount_type == "payment" &&
        subaccount.currency == this.currencyTarget.value.toLowerCase()
    );
    let subaccount_name = subaccounts[0].name;
    let payment_method = subaccounts[0].payment_method;
    this.paymentMethodTarget.value = payment_method;
    this.get_subaccount_rate(event, subaccount_name, payment_method);
  }

  completePaymentWithSubaccount(
    event,
    subaccount_rate,
    subaccount_name,
    payment_method
  ) {
    if (payment_method == "paystack") {
      this.handlePaystackPayment(event, subaccount_name, subaccount_rate);
    } else if (payment_method == "flutterwave") {
      this.handleFlutterwavePayment(event, subaccount_name, subaccount_rate);
    } else if (payment_method == "remita_biller") {
      this.handleRemitaBillerPayment(event, subaccount_name, subaccount_rate);
    } else {
      this.handleRemitaPayment(event, subaccount_name, subaccount_rate);
    }
  }

  handlePaystackPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    var _selfInner = this;
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: _selfInner.paystackPublicTarget.value, // Replace with your public key
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value * 100,
      subaccount: subaccount_name || "",
      bearer: "account",
      currency: _selfInner.currencyTarget.value,
      ref: "" + Math.floor(Math.random() * 1000000000 + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      // label: "Optional string that replaces customer email"
      onSuccess: (transaction) => {
        _selfInner.referenceTarget.value = transaction.reference;
        let message = "Payment complete! Reference: " + transaction.reference;
        console.log(message);
        _selfInner.formTarget.submit();
      },
      onCancel: () => {
        // user closed popup
      },
    });
  }

  handleRemitaBillerPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    const _selfInner = this;
    const params = {
      client_id: _selfInner.client_idTarget.value,
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      payment_type: "payment",
    };
  
    $.ajax({
      url: "/generate_invoice",
      type: "post",
      dataType: "json",
      data: params,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        console.log(data);
        if (data.statuscode == "025") {
          _selfInner.processInvoice(data["RRR"]);
        } else {
          console.log("Error while generating invoice!");
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("Error while generating invoice!");
      },
    });
  }

  handleRemitaPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    var _selfInner = this;
    var params = {
      client_id: _selfInner.client_idTarget.value,
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      payment_type: "payment",
    };

    $.ajax({
      url: "/generate_invoice",
      type: "post",
      dataType: "json",
      data: params,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        console.log(data);
        if (data.statuscode == "025") {
          _selfInner.processInvoice(data["RRR"]);
        } else {
          console.log("Error while generating invoice!");
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("Error while generating invoice!");
      },
    });
  }

  handleFlutterwavePayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    var _selfInner = this;

    const modal = FlutterwaveCheckout({
      public_key: _selfInner.flutterwavePublicTarget.value,
      tx_ref: "" + Math.floor(Math.random() * 1000000000 + 1),
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      subaccounts: subaccount_name
        ? [
            {
              id: subaccount_name,
              transaction_charge_type: "flat_subaccount",
              transaction_charge:
                subaccount_rate * _selfInner.amountTarget.value,
            },
          ]
        : [],
      payment_options: "card, banktransfer, ussd",
      customer: {
        email: _selfInner.emailTarget.value,
        phone_number: _selfInner.phoneTarget.value,
        name:
          _selfInner.firstNameTarget.value + _selfInner.lastNameTarget.value,
      },
      callback: function (payment) {
        _selfInner.referenceTarget.value = payment.id;
        let message = "Payment complete! Reference: " + payment.id;
        _selfInner.formTarget.submit();
        modal.close();
      },
      onclose: function (incomplete) {
        if (incomplete === true) {
          // Record event in analytics
        }
      },
    });
  }

  get_subaccount_rate(event, subaccount_name, payment_method) {
    var params = {
      subaccount_name: subaccount_name,
      payment_method: payment_method,
    };
    var _self = this;

    $.ajax({
      url: "/get_subaccount_rate",
      type: "get",
      dataType: "json",
      data: params,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        if (data.errors != null) {
          console.log("callback Error on subaccounts rate change", data.errors);
        } else {
          _self.completePaymentWithSubaccount(
            event,
            data,
            subaccount_name,
            payment_method
          );
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("callback Error on subaccounts rate change");
      },
    });
  }

  processInvoice(rrr) {
    var _selfInvoice = this;
    let publicKey;
    if (this.paymentMethodTarget.value === 'remita_biller') {
      publicKey = this.remitaBillerPublicTarget.value;
    } else {
      publicKey = this.remitaPublicTarget.value;
    }
    var remita = RmPaymentEngine.init({
      key: publicKey,
      processRrr: true,
      // transactionId: Math.floor(Math.random()*1101233), // Replace with a reference you generated or remove the entire field for us to auto-generate a reference for you. Note that you will be able to check the status of this transaction using this transaction Id
      extendedData: {
        customFields: [
          {
            name: "rrr",
            value: rrr,
          },
        ],
      },
      onSuccess: function (response) {
        console.log("callback Successful Response", response);
        _selfInvoice.referenceTarget.value = response.paymentReference;
        let message =
          "Payment complete! Reference: " + response.paymentReference;
        _selfInvoice.formTarget.submit();
      },
      onError: function (response) {
        console.log("callback Error Response", response);
      },
      onClose: function () {},
    });

    remita.showPaymentWidget();
  }

  changePurpose(event) {
    purposes = JSON.parse(this.purposesTarget.value);
    if (!this.purposes) {
      console.error("Purposes data is undefined.");
      return;
    }
    try {
      const selectedPurposeId = parseInt(event.target.value);
      const selectedPurpose = JSON.parse(this.purposesTarget.value).find(
        (p) => p[1] === selectedPurposeId
      );

      if (selectedPurpose) {
        const currencySelect = this.currencyTarget;
        currencySelect.innerHTML = "";
        selectedPurpose[2].forEach((currency) => {
          const option = document.createElement("option");
          option.value = currency;
          switch (currency.toUpperCase()) {
            case "USD":
              option.text = "US Dollars";
              break;
            case "NGN":
              option.text = "Nigerian Naira";
              break;
            case "EUR":
              option.text = "Euro";
              break;
            case "GBP":
              option.text = "British Pounds";
              break;
            default:
              option.text = `${currency}`;
          }
          currencySelect.appendChild(option);
        });

        if (selectedPurpose[2].length > 0) {
          this.currencyTarget.value = selectedPurpose[2][0];
          this.updateAmount(selectedPurposeId, selectedPurpose[2][0]);
        }
      }
    } catch (error) {
      console.error("Error handling purpose change:", error);
    }
  }

  changeCurrency(event) {
    const selectedCurrency = event.target.value;
    const purposeId = this.purposeTarget.value;

    try {
      this.updateAmount(purposeId, selectedCurrency);
    } catch (error) {
      console.error("Error updating amount:", error);
    }
  }

  updateAmount(purposeId, currency) {
    try {
      const amounts = JSON.parse(this.amountsTarget.value);
      if (
        amounts &&
        amounts[purposeId] &&
        amounts[purposeId][currency] !== undefined
      ) {
        const newAmount = amounts[purposeId][currency];
        this.amountTarget.value = newAmount;
      } else {
        console.error("Amount not available for this purpose and currency.");
      }
    } catch (error) {
      console.error("Error parsing amounts or updating amount:", error);
    }
  }

  validateForm() {
    let isValid = true;

    let subaccounts = JSON.parse(this.subaccountsTarget.value).filter(
      (subaccount) =>
        subaccount.subaccount_type == "payment" &&
        subaccount.currency == this.currencyTarget.value.toLowerCase()
    );

    if (subaccounts.length == 0) {
      this.categoryErrorTarget.innerText = "Subaccount is not selected";
      this.categoryErrorTarget.classList.remove("hide");
      this.categoryErrorTarget.scrollIntoView();
      isValid = false;
      return isValid;
    } else {
      this.categoryErrorTarget.classList.add("hide");
    }

    let requiredFieldSelectors = "textarea:required, input:required";
    let requiredFields = this.formTarget.querySelectorAll(
      requiredFieldSelectors
    );

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();
        isValid = false;
        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove(
          "form__input-label-error"
        );
        // field.nextElementSibling.classList.add("hide");
      }
    });

    if (!isValid) {
      return false;
    }

    let invalidFields = this.formTarget.querySelectorAll("input:invalid");

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();
        isValid = false;
        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove(
          "form__input-label-error"
        );
        field.nextElementSibling.classList.add("hide");
      }
    });

    return isValid;
  }

  validateFirstName() {
    const pattern = /^(?!.*([^\w])\1)[a-zA-Z.\- ']+$/;
    const input = this.firstNameTarget;
    const errorMessage = this.firstNameErrorTarget;

    if (pattern.test(input.value)) {
      errorMessage.style.display = "none";
    } else {
      errorMessage.style.display = "block";
      errorMessage.textContent =
        "Please enter a valid name. Only letters, spaces, hyphens, and periods are allowed.";
    }
  }

  validateLastName() {
    const pattern = /^(?!.*([^\w])\1)[a-zA-Z.\- ']+$/;
    const input = this.lastNameTarget;
    const errorMessage = this.lastNameErrorTarget;

    if (pattern.test(input.value)) {
      errorMessage.style.display = "none";
    } else {
      errorMessage.style.display = "block";
      errorMessage.textContent =
        "Please enter a valid last name. Only letters, spaces, hyphens, and periods are allowed.";
    }
  }

  showError(isShown, field, errorMsg = "") {
    var _elem = field;
    this.errorContainerTargets.forEach((errorContainer) => {
      const errorType = errorContainer.dataset.errorType;
      if (_elem.id == errorType) {
        errorContainer.innerText = isShown ? errorMsg || "" : "";
      }
    });
  }

  // Countries

  changeCountry(event) {
    let stateError = document.getElementById("paymentsStateError");
    if (
      event.target.selectedOptions[0] &&
      event.target.selectedOptions[0].value != "-1"
    ) {
      this.changeStateByCountry(event.target.selectedOptions[0].value, null);
      this.stateTarget.disabled = false;
      stateError.style.display = "none";
    } else {
      this.changeStateByCountry(null, null);
      this.stateTarget.disabled = true;
    }
  }

  changeStateByCountry(country, selectedState) {
    if (country) {
      this.fillStatesOfCountry(country, selectedState);
    } else {
      this.stateTarget.textContent = "";
    }
  }

  fillStatesOfCountry(country, selectedState) {
    var params_data = {
      data: {
        country: country,
      },
    };
    console.log(params_data);

    var _self = this;
    $.ajax({
      url: "/countries/get_states_by_country",
      type: "get",
      dataType: "json",
      data: params_data,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        _self.appendStates(data);
        _self.stateTarget.value = selectedState;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        _self.appendStates([]);
        _self.stateTarget.value = selectedState;
      },
    });
  }

  appendStates(states) {
    this.stateTarget.textContent = "";
    for (var i = 0; i < states.length; i++) {
      var newElement = document.createElement("option");
      newElement.value = states[i][1];
      newElement.text = states[i][0];
      this.stateTarget.appendChild(newElement);
    }
  }
}
